export default [
  {
    key: 'name',
    label: 'field.name',
    type: 'text',
    operator: '%',
  },
  {
    key: 'phoneNumber',
    label: 'field.phone',
    type: 'tel',
    operator: '%',
    searchField: 'username',
  },
  {
    key: 'provinceId',
    label: 'field.province',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'province',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'createdBy',
    label: 'field.bdPic',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'user',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'isEnable',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
  },
]
